import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { memo, useCallback } from 'react';

const InputField = memo((props) => {
	const { showAsterisk = true, type, label, required, variant, helperText, inputProps, ...otherProps } = props;
	const [field, meta] = useField(otherProps);
	const { validateField } = useFormikContext();

	const handleChange = useCallback(
		(e) => {
			if (type === 'tel') {
				const regex = /^[0-9\b]+$/;
				if (e.target.value === '' || regex.test(e.target.value)) {
					field.onChange(e);
				}
			} else {
				field.onChange(e);
			}
		},
		[field, type]
	);

	const handleBlur = useCallback(() => {
		try {
			validateField(otherProps.name);
		} catch (error) {}
	}, [validateField, otherProps.name]);

	return (
		<TextField
			error={!!(meta.touched && meta.error)}
			helperText={meta.touched && meta.error ? meta.error : helperText}
			type={type || 'text'}
			required={required}
			label={label}
			variant={variant || 'outlined'}
			InputLabelProps={{
				required: showAsterisk && required,
			}}
			{...field}
			onChange={handleChange}
			onBlur={handleBlur}
			inputProps={{
				readOnly: props.readOnly,
				...inputProps,
			}}
			{...otherProps}
		/>
	);
});

export default InputField;
