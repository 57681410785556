import { useNavigate, useParams } from 'react-router-dom';
import { Apartment, HelpOutline, Language, LinkedIn, LocationOn, TrendingDown, TrendingUp } from '@mui/icons-material';
import { useAuthContext, usePrivateAppContext } from '../../context';
import React, { useEffect, useState } from 'react';
import { apiClient, beautifyUrl, endpoints, formatUrl } from '../../utils';
import { toast } from 'react-toastify';
import { Box, Divider, Skeleton, Stack, Tab, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ChartsTab from './tabs/ChartsTab';
import { FundingRoundsTab } from './tabs/FundingRoundsTab';
import TradingActivity from './TradingData/TradingActivity';
import { InstitutionalInfoFieldWrapper } from './style';
import InquiriesInfo from './TradingData/InquiriesInfo';
import { strings } from '../../utils/strings';
import InvestorsTab from './tabs/InvestorsTab';
import InquiriesTab from './tabs/InquiriesTab';
import CompanyPageTitle from '../../components/CompanyPageTitle';
import Calculator from './components/Calculator';
import LastUpdates from './components/LastUpdates';
import { useCompanyContext } from '../../context/CompanyContext';

const MIN_TICKET = 50000;

const CompanyPageView = () => {
	const { companyId } = useParams();
	const { setCurrent } = usePrivateAppContext();
	const { data, loading } = useCompanyContext();
	const { user, reloadUserData } = useAuthContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [companyInquiries, setCompanyInquiries] = useState([]);

	useEffect(() => {
		const loadInquiries = async () => {
			try {
				const res = await apiClient.post(endpoints.getAnonymousInquiries, {
					companyIds: [companyId],
				});
				return res.data ?? [];
			} catch (err) {
				toast.error(strings.errorMessages.inquiry.loadMany);
				return [];
			}
		};

		if (companyId && (user?.isLevel2 || user?.isLevel3)) {
			loadInquiries().then((res) => setCompanyInquiries(res));
		} else {
			setCompanyInquiries([]);
		}
	}, [companyId, user]);

	const navigate = useNavigate();

	const handleLogInterest = async (selectedInquiries = []) => {
		if (!(user?.isLevel2 || user?.isLevel3)) {
			const questionnairePayload = {
				companyName: data.overviewData?.name,
				companyId: data.overviewData?.id,
			};
			setCurrent({ ...questionnairePayload });
		} else {
			setCurrent({ selectedInquiries: selectedInquiries });
		}
		navigate(`/company/${companyId}/send-inquiry`);
	};

	const renderInfoField = (field, index) => {
		return (
			<InstitutionalInfoFieldWrapper key={index} $even={index % 2 === 0}>
				<Stack direction={'row'} spacing={1} alignItems={'center'}>
					<Typography variant={'body1'} color={'text.secondary'} alignItems={'end'}>
						{field.label}
					</Typography>
					{field.tooltip && (
						<Tooltip title={field.tooltip} placement="top" arrow>
							<HelpOutline color={'disabled'} sx={{ fontSize: 20 }} />
						</Tooltip>
					)}
				</Stack>
				<Stack spacing={0} direction={'column'} alignItems={'end'}>
					<Stack spacing={1} direction={'row'} sx={{ paddingTop: '4px' }}>
						{field.trend === 'up' && <TrendingUp sx={{ color: 'success.main', fontSize: 20 }} />}
						{field.trend === 'down' && <TrendingDown sx={{ color: 'error.main', fontSize: 20 }} />}
						<Typography variant={'body1'}>{field.primary}</Typography>
					</Stack>
					{field.secondary && field.secondary !== '' && (
						<Typography variant={'caption'} color={'text.secondary'} sx={{ minHeight: '2em' }}>
							{field.secondary}
						</Typography>
					)}
				</Stack>
			</InstitutionalInfoFieldWrapper>
		);
	};

	const renderInfoFields = () => {
		const infoFields = data.overviewData?.formattedFields?.fields ?? [];
		const infoFieldsLength = infoFields.length;
		const centeredIndex = Math.ceil(infoFieldsLength / 2);

		return (
			<Stack spacing={isMobile ? '6px' : '40px'} direction={isMobile ? 'column' : 'row'}>
				<Stack spacing={'4px'} flex={1}>
					{infoFields.slice(0, centeredIndex).map((field, index) => {
						return renderInfoField(field, index);
					})}
				</Stack>
				{!isMobile && <Divider orientation="vertical" flexItem />}
				<Stack spacing={'4px'} flex={1}>
					{infoFields.slice(centeredIndex, infoFieldsLength).map((field, index) => {
						return renderInfoField(field, index);
					})}
					<InstitutionalInfoFieldWrapper $even={infoFields.length % 2 === 0}>
						<Typography variant={'body1'} color={'text.secondary'} alignItems={'end'}>
							Trends
						</Typography>
						{data?.overviewData?.tradingActivitySell ? (
							<TradingActivity
								data={[100 - data?.overviewData.tradingActivitySell, data?.overviewData.tradingActivitySell]}
								dimensions={{ width: 120, height: 12 }}
							/>
						) : (
							'N/A'
						)}
					</InstitutionalInfoFieldWrapper>
				</Stack>
			</Stack>
		);
	};

	const tabs = [
		{
			value: 'details',
			label: 'details',
			children: <>{renderInfoFields()}</>,
			visible: true,
		},
		{
			value: 'charts',
			label: 'charts',
			children: <ChartsTab data={data} />,
			visible: true,
		},
		{
			value: 'fundingRounds',
			label: 'funding rounds',
			children: <FundingRoundsTab fundingData={data.fundingData} />,
			visible: true,
		},

		(user?.isLevel2 || user?.isLevel3) && {
			value: 'aggregated',
			label: 'Aggregated activity',
			children: (
				<InquiriesInfo
					inquiries={companyInquiries.filter(
						(d) => d.fields['Block Size Ticket'] && d.fields['Block Size Ticket'] > MIN_TICKET
					)}
				/>
			),
			visible: true,
		},
		user?.isLevel3 && {
			value: 'inquiries',
			label: 'current activity',
			children: <InquiriesTab inquiries={companyInquiries} handleLogInterest={handleLogInterest} />,
			visible: true,
		},
		{
			value: 'investors',
			label: 'investors',
			children: <InvestorsTab investors={data.investors} />,
			visible: true,
		},
	];
	const [openedTab, setOpenedTab] = useState(tabs[0].value);

	const getCompanyIsFollowed = (user, companyId) =>
		Boolean(user && user.fields.Follows && user.fields.Follows.indexOf(companyId) > -1);
	const [companyIsFollowed, setCompanyIsFollowed] = useState(getCompanyIsFollowed(user, companyId));

	useEffect(() => {
		setCompanyIsFollowed(getCompanyIsFollowed(user, companyId));
	}, [companyId, user]);

	const handleFollowCompany = async () => {
		let companies = user.fields.Follows ? user.fields.Follows : [];

		if (companies.includes(companyId)) {
			companies = companies.filter((company) => company !== companyId);
			setCompanyIsFollowed(false);
		} else {
			companies.push(companyId);
			setCompanyIsFollowed(true);
		}

		try {
			const res = await apiClient.post(endpoints.followCompany, {
				companies,
				user: user.id,
			});

			if (res.data) {
				await reloadUserData();
			}
		} catch (err) {
			toast.error(strings.errorMessages.company.follow);
		}
	};

	const getInfoItems = () => {
		const items = [];

		if (data.overviewData?.sectors) {
			items.push({
				icon: Apartment,
				title: data.overviewData?.sectors,
			});
		}

		if (data.overviewData?.location) {
			items.push({
				icon: LocationOn,
				title: data.overviewData?.location,
			});
		}

		if (data.overviewData?.website) {
			items.push({
				icon: Language,
				title: beautifyUrl(data.overviewData?.website),
				link: formatUrl(data.overviewData?.website),
			});
		}

		if (data.overviewData?.linkedinUrl) {
			items.push({
				icon: LinkedIn,
				title: data.overviewData?.linkedinUrl,
				link: data.overviewData?.linkedinUrl,
			});
		}

		return items;
	};

	return (
		<>
			<CompanyPageTitle
				breadcrumbs={[
					{
						title: 'Companies',
						link: '/companies',
					},
					{
						title: data.overviewData?.name,
					},
				]}
				title={data.overviewData?.name}
				infoItems={getInfoItems()}
				isLoading={loading}
				favoriteButton={{
					value: companyIsFollowed,
					onChange: handleFollowCompany,
				}}
				extraButton={
					user?.instie && {
						label: 'Add new bid/offer indication',
						onClick: () => handleLogInterest([]),
						dataCy: 'log-interest-button',
					}
				}
			/>
			{!user?.instie && (
				<Grid
					container
					spacing={isMobile ? 2 : 3}
					direction={isMobile ? 'column-reverse' : undefined}
					alignItems={'stretch'}
					mb={isMobile ? 2 : 3}
				>
					<Grid size={isMobile ? 12 : 4}>
						<LastUpdates loading={loading} />
					</Grid>
					<Grid size={isMobile ? 12 : 8}>
						<Calculator loading={loading} />
					</Grid>
				</Grid>
			)}
			{loading && <Skeleton variant="rectangular" height={320} />}
			{!loading && (
				<TabContext value={openedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList
							onChange={(event, value) => setOpenedTab(value)}
							variant={isMobile ? 'scrollable' : undefined}
							allowScrollButtonsMobile={true}
						>
							{tabs
								.filter((tab) => tab.visible)
								.map((tab, i) => (
									<Tab key={i} label={tab.label} value={tab.value} />
								))}
						</TabList>
					</Box>
					{tabs
						.filter((tab) => tab.visible)
						.map((tab, i) => (
							<TabPanel key={i} sx={{ paddingLeft: 0, paddingRight: 0 }} value={tab.value}>
								{tab.children}
							</TabPanel>
						))}
				</TabContext>
			)}
		</>
	);
};

export default CompanyPageView;
