export const strings = {
	successMessages: {
		duplicated: 'Duplicated successfully!',
		inquiry: {
			canceled: 'Inquiry cancelled.',
			enabled: 'Inquiry enabled.',
			deleted: 'Inquiry deleted.',
			updated: 'Inquiry updated.',
			saved: 'Inquiry saved.',
			draftSellerTicketCreated: 'Draft seller ticket created.',
			uploadsCopied: 'Uploads copied.',
			sentForSignature: 'Inquiry sent for signature.',
		},
		inquisition: {
			saved: 'Inquisition saved.',
			duplicated: 'Inquisition duplicated.',
			deleted: 'Inquisition deleted.',
		},
		investor: {
			deleted: 'Investor deleted.',
			updated: 'Investor updated.',
			saved: 'Investor saved.',
		},
		contentSnippet: {
			deleted: 'Content snippet deleted.',
			updated: 'Content snippet updated.',
			saved: 'Content snippet saved.',
		},
		accreditedInvestor: {
			deleted: 'Accredited investor deleted.',
			updated: 'Accredited investor updated.',
			saved: 'Accredited investor saved.',
		},
		user: {
			granted: 'Access granted.',
			updated: 'User updated.',
			created: 'User created.',
			deleted: 'User deleted.',
			impersonated: 'Check Slack #tokens channel for impersonation link.',
		},
		masterBid: {
			deleted: 'Master bid deleted.',
			saved: 'Master bid saved.',
		},
		masterOffer: {
			deleted: 'Master offer deleted.',
			saved: 'Master offer saved.',
		},
		bid: {
			answerSaved: 'Answer saved.',
		},
		offer: {
			answerSaved: 'Your answer is saved!',
		},
		company: {
			saved: 'Company saved.',
			fundingRoundSaved: 'Funding round saved.',
			priceHistorySaved: 'Price history saved.',
		},
		message: {
			sentMany: 'Messages sent.',
		},
		email: {
			sentMany: 'Emails sent.',
		},
		task: {
			statusChanged: 'Task status changed.',
			saved: 'Task saved.',
		},
		compliance: {
			updated: 'Compliance updated.',
		},
		entity: {
			deleted: 'Entity deleted.',
			updated: 'Entity updated.',
			saved: 'Entity saved.',
		},
		teamMember: {
			deleted: 'Team member deleted.',
			updated: 'Team member updated.',
			saved: 'Team member saved.',
		},
		chat: {
			resolved: 'Chat resolved.',
		},
		targetPrice: {
			startMonitor: 'Monitoring enabled for price updates.',
			stopMonitor: 'Price monitoring disabled.',
			stopMonitorCompany: 'Price monitoring for {company} disabled.',
		},
	},
	errorMessages: {
		default: 'Something went wrong. Try again.',
		rating: {
			update: 'Error updating rating.',
		},
		inquiry: {
			loadMany: 'Error loading inquiries.',
			load: 'Error loading inquiry.',
			cancel: 'Error canceling inquiry.',
			enable: 'Error enabling inquiry.',
			delete: 'Error deleting inquiry.',
			update: 'Error updating inquiry.',
			save: 'Error saving inquiry.',
			saveAnswers: 'Error saving answers. Try again later.',
			copyUploads: 'Error copying uploads.',
		},
		inquisition: {
			loadMany: 'Error loading inquisitions.',
			load: 'Error loading inquisition.',
			update: 'Error updating inquisition.',
			save: 'Error saving inquisition.',
			duplicate: 'Error duplicating inquisition.',
		},
		contentSnippet: {
			loadMany: 'Error loading content snippets.',
			load: 'Error loading content snippet.',
			update: 'Error updating content snippet.',
			save: 'Error saving content snippet.',
			delete: 'Error deleting content snippet.',
		},
		investor: {
			loadMany: 'Error loading investors.',
			load: 'Error loading investor.',
			delete: 'Error deleting investor.',
			save: 'Error saving investor.',
			update: 'Error updating investor.',
		},
		accreditedInvestor: {
			loadMany: 'Error loading accredited investors.',
			load: 'Error loading accredited investor.',
			delete: 'Error deleting accredited investor.',
			save: 'Error saving accredited investor.',
			update: 'Error updating accredited investor.',
		},
		company: {
			follow: 'Error following company.',
			loadMany: 'Error loading companies.',
			load: 'Error loading company.',
			save: 'Error saving company.',
			update: 'Error updating company.',
			fundingRounds: {
				load: 'Error loading funding rounds.',
				save: 'Error saving funding rounds.',
				update: 'Error updating funding rounds.',
			},
			priceHistory: {
				load: 'Error loading price history.',
				save: 'Error saving price history.',
				update: 'Error updating price history.',
				delete: 'Error deleting price history.',
				duplicate: 'Error duplicating price history.',
			},
		},
		user: {
			emailExists: 'User email already exists.',
			load: 'Error loading user.',
			loadMany: 'Error loading users.',
			filter: 'Error filtering users.',
		},
		chat: {
			load: 'Error loading chat.',
			loadMany: 'Error loading chats.',
		},
		activityLogs: {
			load: 'Error loading activity logs.',
		},
		masterBid: {
			delete: 'Error deleting master bid.',
			load: 'Error loading master bid.',
			save: 'Error saving master bid.',
			update: 'Error updating master bid.',
		},
		masterOffer: {
			delete: 'Error deleting master offer.',
			load: 'Error loading master offer.',
			save: 'Error saving master offer.',
			update: 'Error updating master offer.',
		},
		overview: {
			load: 'Error loading overview.',
		},
		windowShopper: {
			loadMany: 'Error loading window shoppers.',
		},
		task: {
			load: 'Error loading task.',
		},
		message: {
			send: 'Error sending message. Try again later.',
		},
		email: {
			send: 'Error sending email. Try again later.',
		},
		form: {
			submit: 'Error submitting form.',
		},
		compliance: {
			update: 'Error updating compliance. Try again later.',
			saveFiles: 'Error saving files.',
		},
		entity: {
			loadMany: 'Error loading entities.',
			delete: 'Error deleting entity.',
			update: 'Error updating entity.',
		},
		teamMember: {
			loadMany: 'Error loading team members.',
			delete: 'Error deleting team member.',
			save: 'Error saving team member.',
		},
		onboarding: {
			save: 'There was a problem saving your answers. Please reload the page and try again.',
		},
	},
};
