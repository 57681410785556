import { Breadcrumbs, Button, Link, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import React from 'react';

interface Breadcrumb {
	title: string;
	link?: string;
}

interface PrimaryButton {
	label: string;
	onClick: () => void;
	dataCy?: string;
}

interface PageTitleProps {
	isLoading?: boolean;
	isOnlyBreadcrumbs?: boolean;
	breadcrumbs?: Breadcrumb[];
	title?: string;
	primaryButton?: PrimaryButton;
	titleButton?: React.ReactNode;
	rightPanel?: React.ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { isLoading = false, isOnlyBreadcrumbs = false } = props;
	const { breadcrumbs = [], title, primaryButton, titleButton, rightPanel } = props;

	return (
		<Stack
			spacing={isMobile ? '6px' : 2}
			direction={'column'}
			paddingTop={isMobile ? 2 : 3}
			paddingBottom={isMobile ? 2 : 3}
		>
			{isLoading ? (
				<Skeleton variant="rectangular" width={isMobile ? 225 : 200} height={24} />
			) : (
				<Breadcrumbs>
					{breadcrumbs.map((breadcrumb, i) => {
						if (breadcrumb.link) {
							return (
								<Link key={i} component={RouterLink} to={breadcrumb.link} underline="hover" color="inherit">
									{breadcrumb.title}
								</Link>
							);
						} else {
							return (
								<Typography key={i} color="text.primary">
									{breadcrumb.title}
								</Typography>
							);
						}
					})}
				</Breadcrumbs>
			)}
			{!isOnlyBreadcrumbs && (Boolean(title) || Boolean(primaryButton) || Boolean(rightPanel)) && (
				<Stack spacing={1} direction={'column'}>
					<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
						<Stack
							spacing={3}
							direction={'row'}
							alignItems={isMobile ? 'center' : 'flex-end'}
							justifyContent={isMobile ? 'space-between' : undefined}
							width={isMobile ? '100%' : undefined}
						>
							{isLoading ? (
								<Skeleton variant="rectangular" width={isMobile ? '50%' : 356} height={isMobile ? 33 : 53} />
							) : (
								title && (
									<Typography variant={'h4'} data-cy={'company-title'}>
										{title}
									</Typography>
								)
							)}
							{titleButton}
						</Stack>
						{primaryButton && !isMobile ? (
							isLoading ? (
								<Skeleton variant="rectangular" width={180} height={36} />
							) : (
								<Button
									size="medium"
									variant="contained"
									color="primary"
									startIcon={<Add />}
									onClick={primaryButton?.onClick}
									data-cy={primaryButton?.dataCy}
								>
									{primaryButton?.label}
								</Button>
							)
						) : null}
						{primaryButton && isMobile && !isLoading && (
							<Button
								size="large"
								variant="contained"
								color="primary"
								startIcon={<Add />}
								onClick={primaryButton?.onClick}
								data-cy={primaryButton?.dataCy}
								sx={{
									position: 'fixed',
									bottom: 24,
									left: 24,
									right: 24,
									zIndex: 1051,
									height: 48,
									boxShadow: theme.shadows[24],
								}}
							>
								{primaryButton?.label}
							</Button>
						)}
						{rightPanel}
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export default PageTitle;
