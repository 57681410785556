export interface IContentSnippet {
	id: string;
	name: string;
	content: string;
	company?: any;
	tags?: Array<any>;
	createdAt: Date | string;
	status: CONTENT_SNIPPET_STATUS;
}

export enum CONTENT_SNIPPET_STATUS {
	NEW = 'New',
	DONE = 'Done',
}
