import { Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';

const OnboardingSkeleton = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	if (isMobile) {
		return (
			<Stack spacing={3}>
				<Skeleton variant="rectangular" width={'100%'} height={343} />
				<Skeleton variant="rectangular" width={'100%'} height={164} />
			</Stack>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid size={4}>
				<Skeleton variant="rectangular" width={'100%'} height={350} />
			</Grid>
			<Grid size={8}>
				<Stack spacing={'28px'}>
					<Skeleton variant="rectangular" width={'80%'} height={46} />
					<Skeleton variant="rectangular" width={'60%'} height={46} />
					<Skeleton variant="rectangular" width={'100%'} height={46} />
					<Skeleton variant="rectangular" width={'80%'} height={46} />
					<Skeleton variant="rectangular" width={'60%'} height={46} />
				</Stack>
			</Grid>
		</Grid>
	);
};

export default OnboardingSkeleton;
