// handleSubmitWithValidation.js
import { toast } from 'react-toastify';

// Function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

// Function to find the first error and format it
const findFirstError = (errorsObj, parentField = '') => {
	for (const [key, value] of Object.entries(errorsObj)) {
		const fieldName = parentField ? `${parentField}` : key; // Use the parent field name without index

		if (Array.isArray(value)) {
			// If value is an array, check the first item for errors
			if (value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
				const nestedError = findFirstError(value[0], fieldName); // Check the first object in the array
				if (nestedError) {
					return nestedError; // Return the first nested error found
				}
			}
		} else if (typeof value === 'object' && value !== null) {
			const nestedError = findFirstError(value, fieldName); // Recursively check nested errors
			if (nestedError) {
				return nestedError; // Return the first nested error found
			}
		} else {
			return `${capitalizeFirstLetter(fieldName)}: ${value}`; // Return the first error message found
		}
	}
	return null; // No errors found
};

// Main function to handle validation and submission
export const handleSubmitWithValidation = async (validateForm, handleSubmit, values, containerId) => {
	const errors = await validateForm();
	const firstError = findFirstError(errors);

	if (firstError) {
		toast.error(firstError, {
			containerId: containerId,
		});
	} else {
		handleSubmit(values);
	}
};
