import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface ConfirmDialogProps {
	isOpen: boolean;
	handleClose: () => void;
	submitForm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
	const { isOpen, handleClose, submitForm } = props;

	return (
		<Dialog open={isOpen} onClose={handleClose}>
			<DialogTitle>{'Complete Submission?'}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					You're about to submit your information. Once submitted, no further edits will be possible. Our team will
					review your responses and follow up with you shortly.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={submitForm} autoFocus variant={'contained'}>
					submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
