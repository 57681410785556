import { Autocomplete, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';

const CountrySelect = ({
	showAsterisk = true,
	options,
	handleChange,
	setFieldValue,
	name,
	label,
	value,
	required,
	fullWidth,
	helperText,
	...props
}) => {
	const [, meta, helpers] = useField(name);
	const { validateField } = useFormikContext();

	return (
		<Autocomplete
			disablePortal
			options={options}
			value={value}
			isOptionEqualToValue={(option, value) => option.label === value}
			onChange={(event, value) => {
				setFieldValue(name, value ? value.label : '');
			}}
			onBlur={() => {
				helpers.setTouched(true);
				validateField(name);
			}}
			renderInput={(params) => {
				const inputProps = params.inputProps;
				inputProps.autoComplete = 'off';
				return (
					<TextField
						{...params}
						name={name}
						error={Boolean(meta.touched && meta.error)}
						helperText={meta.touched && meta.error ? meta.error : helperText}
						inputProps={inputProps}
						label={label}
						onChange={handleChange}
						required={required}
						fullWidth={fullWidth}
						InputLabelProps={{
							required: showAsterisk && required,
						}}
					/>
				);
			}}
			{...props}
		/>
	);
};

export default CountrySelect;
