import { useFormikContext } from 'formik';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Link,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useMemo } from 'react';
import { getData } from 'country-list';
import { HelpOutline } from '@mui/icons-material';
import InputField from '../../../../components/FormComponents/InputField';
import Checkbox from '../../../../components/FormComponents/Checkbox';
import CountrySelect from '../../../../components/FormComponents/CountrySelect';
import { timeFormat } from 'd3';
import InfoBlock from '../../../../components/InfoBlock';
import FileUploadInput from '../../../../components/FormComponents/FileUploadInput';
import GoogleMapSelect from '../../../../components/FormComponents/GoogleMapSelect';
import { OnboardingForm } from '../../formConstants';

interface ShareholderProfileBlockProps {
	locked: boolean;
}

const ShareholderProfileBlock: React.FC<ShareholderProfileBlockProps> = (props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { values, handleChange, isSubmitting, setFieldValue, errors, touched, validateField } =
		useFormikContext<OnboardingForm>();
	const { locked } = props;

	const countries = useMemo(() => getData().map((country) => ({ label: country.name, code: country.code })), []);

	const renderExplanationInput = (name) => (
		<Grid size={12}>
			<InputField
				label="Provide a written explanation"
				multiline
				fullWidth
				rows={4}
				name={name}
				disabled={locked || isSubmitting}
				required
				showAsterisk={false}
			/>
		</Grid>
	);

	return (
		<Stack
			spacing={4}
			sx={{ p: isMobile ? 3 : '32px 40px', borderRadius: '4px', border: `1px solid ${theme.palette.divider}` }}
		>
			<Stack spacing={2}>
				<Typography variant="h5">Shareholder Profile</Typography>
				<Stack spacing={'12px'}>
					<Typography variant="body2" color={'text.secondary'}>
						ShareWell | SaxonWeber and its designated agents and representatives shall maintain all information received
						from this authorization in a confidential manner according to Privacy of{' '}
						<Link underline={'hover'} href={'https://www.sec.gov/rules/final/34-42974.htm'} target={'_blank'}>
							Consumer Financial Information (Regulation S-P)
						</Link>
						.
					</Typography>
					<Typography variant="body2" color={'text.secondary'}>
						I hereby authorize ShareWell | SaxonWeber and its designated agents and representatives to share and retain
						any information, records and data received in connection with this authorization as required or advisable to
						comply with any applicable legal and regulatory obligations. I acknowledge that I have read this
						authorization and release, fully understand it, and voluntarily agree to its provisions.
					</Typography>
				</Stack>
			</Stack>
			<Stack spacing={isMobile ? 3 : 6}>
				<Box>
					<Grid container spacing={[3, 4]}>
						<Grid size={12}>
							<InfoBlock title={'General information'}>
								{/* Middle Names */}
								<Grid size={12}>
									<InputField
										label={'Middle Names'}
										name="middleNames"
										fullWidth
										disabled={!values.hasMiddleNames || locked || isSubmitting}
										required={values.hasMiddleNames}
										showAsterisk={false}
									/>
								</Grid>
								<Grid size={12} mt={-2}>
									<Checkbox
										name="hasMiddleNames"
										label="I don't have middle names"
										checked={!values.hasMiddleNames}
										onChange={(e) => {
											setFieldValue('hasMiddleNames', !e.currentTarget.checked).then(() => {
												validateField('hasMiddleNames');
											});
											e.currentTarget.checked && setFieldValue('middleNames', '', false);
										}}
										disabled={locked || isSubmitting}
									/>
								</Grid>
								{/* Phone Number */}
								<Grid size={12}>
									<InputField
										label={'Phone Number'}
										required
										showAsterisk={false}
										name="phoneNumber"
										type="tel"
										pattern="[0-9]*"
										fullWidth
										disabled={locked || isSubmitting}
									/>
								</Grid>
								{/* SSN (or Tax ID) */}
								<Grid size={12}>
									<Stack spacing={1} direction={'row'} alignItems={'center'}>
										<InputField
											label={'SSN (or Tax ID)'}
											required
											showAsterisk={false}
											name="ssnTaxId"
											type="text"
											pattern="[0-9]*"
											fullWidth
											disabled={locked || isSubmitting}
										/>
										<Tooltip
											placement="top"
											title="A Social Security Number or Tax ID is required as part of our compliance obligations under SEC/FINRA rules governing the collection of personal (or entity) information relating to KYC/AML for a securities transaction."
										>
											<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
										</Tooltip>
									</Stack>
								</Grid>
								{/* Date of birth */}
								<Grid size={12}>
									<InputField
										label={'Date of birth'}
										required
										showAsterisk={false}
										name="birthDate"
										value={
											values.birthDate
												? typeof values.birthDate === 'object'
													? timeFormat('%Y-%m-%d')(values.birthDate)
													: values.birthDate
												: ''
										}
										fullWidth
										disabled={locked || isSubmitting}
										type="date"
										InputLabelProps={{
											shrink: true,
											required: false,
										}}
										InputProps={{ inputProps: { max: new Date().toISOString().split('T')[0] } }}
									/>
								</Grid>
								{/* Are you a US citizen? */}
								<Grid size={12}>
									<FormControl component="fieldset" error={touched.usCitizen && Boolean(errors.usCitizen)} required>
										<FormLabel required={false} id="us-citizen-label">
											Are you a US citizen?
										</FormLabel>
										<RadioGroup
											id="usCitizen"
											aria-labelledby="us-citizen-label"
											name="usCitizen"
											tabIndex={-1}
											value={values.usCitizen}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.usCitizen && errors.usCitizen && <FormHelperText>{errors.usCitizen}</FormHelperText>}
									</FormControl>
								</Grid>
								{values.usCitizen === 'No' && (
									<Grid size={12}>
										<FormControl fullWidth>
											<CountrySelect
												showAsterisk={false}
												label="Your country"
												name="citizenship"
												value={values.citizenship}
												required
												aria-labelledby="citizenship-selector-label"
												options={countries}
												handleChange={handleChange}
												setFieldValue={setFieldValue}
												disabled={locked || isSubmitting}
												fullWidth
											/>
										</FormControl>
									</Grid>
								)}
								{/* What type of ID */}
								<Grid size={12}>
									<FormControl component="fieldset" error={touched.idPassport && Boolean(errors.idPassport)} required>
										<FormLabel required={false} id="idPassport-label">
											Select identification document
										</FormLabel>
										<RadioGroup
											id="idPassport"
											aria-labelledby="idPassport-label"
											name="idPassport"
											tabIndex={-1}
											value={values.idPassport}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="ID card"
												label="ID card"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="Passport"
												label="Passport"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="Driver's License"
												label="Driver's License"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.idPassport && errors.idPassport && <FormHelperText>{errors.idPassport}</FormHelperText>}
									</FormControl>
								</Grid>
								{values.idPassport !== '' && (
									<Grid size={12}>
										<FileUploadInput
											label="Document photo"
											id="Identity"
											name="Identity"
											disabled={locked || isSubmitting}
											setFieldValue={setFieldValue}
											value={values.Identity}
											showAsterisk={false}
											fullWidth
											error={touched.Identity && Boolean(errors.Identity)}
											helperText={touched.Identity && Boolean(errors.Identity) ? errors.Identity : undefined}
										/>
									</Grid>
								)}
								{/* Are you currently associated with a Broker-Dealer? */}
								<Grid size={12}>
									<FormControl
										component="fieldset"
										error={touched.brokerDealerAssociated && Boolean(errors.brokerDealerAssociated)}
										required
									>
										<Stack spacing={1} direction={'row'} alignItems={'center'}>
											<FormLabel required={false} id="broker-dealer-associated-label">
												Are you currently associated with a Broker-Dealer?
											</FormLabel>
											<Tooltip
												placement="top"
												title="A broker-dealer firm is in the business of buying and selling securities—stocks, bonds, mutual funds, and certain other investment products—on behalf of its customers (as broker), for its own account (as dealer), or both."
											>
												<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
											</Tooltip>
										</Stack>

										<RadioGroup
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});

												if (value === 'No') {
													setFieldValue('brokerDealerAssociatedEmployed', '');
													setFieldValue('brokerDealerAssociatedControl', '');
													setFieldValue('brokerDealerAssociatedPolitical', '');
												}
											}}
											id="brokerDealerAssociated"
											aria-labelledby="broker-dealer-associated-label"
											name="brokerDealerAssociated"
											tabIndex={-1}
											value={values.brokerDealerAssociated}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.brokerDealerAssociated && errors.brokerDealerAssociated && (
											<FormHelperText>{errors.brokerDealerAssociated}</FormHelperText>
										)}
									</FormControl>
								</Grid>

								{values.brokerDealerAssociated === 'Yes' && (
									// Are you or an immediate family/household member employed by or associated with the securities industry (e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other associated person of a FINRA or MSRB member firm) or a financial services regulator?
									<Grid size={12}>
										<FormControl
											component="fieldset"
											required
											error={touched.brokerDealerAssociatedEmployed && Boolean(errors.brokerDealerAssociatedEmployed)}
										>
											<FormLabel required={false} id="broker-dealer-associated-employed-label">
												Are you or an immediate family/household member employed by or associated with the securities
												industry (e.g. a sole proprietor, partner, officer, director, branch manager, registered
												representative or other associated person of a FINRA or MSRB member firm) or a financial
												services regulator?
											</FormLabel>
											<RadioGroup
												id="brokerDealerAssociatedEmployed"
												aria-labelledby="broker-dealer-associated-employed-label"
												name="brokerDealerAssociatedEmployed"
												tabIndex={-1}
												value={values.brokerDealerAssociatedEmployed}
												onChange={(e) => {
													const { name, value } = e.target;
													setFieldValue(name, value).then(() => {
														validateField(name);
													});
												}}
											>
												<FormControlLabel
													value="Yes"
													label="Yes"
													control={<Radio disabled={isSubmitting || locked} />}
													disabled={isSubmitting || locked}
												/>
												<FormControlLabel
													value="No"
													label="No"
													control={<Radio disabled={isSubmitting || locked} />}
													disabled={isSubmitting || locked}
												/>
											</RadioGroup>
											{touched.brokerDealerAssociatedEmployed && errors.brokerDealerAssociatedEmployed && (
												<FormHelperText>{errors.brokerDealerAssociatedEmployed}</FormHelperText>
											)}
										</FormControl>
									</Grid>
								)}
								{values.brokerDealerAssociatedEmployed === 'Yes' &&
									renderExplanationInput('brokerDealerAssociatedEmployedExplanation')}

								{values.brokerDealerAssociated === 'Yes' && (
									// Are you or an immediate family/household member a control person or affiliate of a publicly traded company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of the board of directors, etc.)?
									<Grid size={12}>
										<FormControl
											component="fieldset"
											error={touched.brokerDealerAssociatedControl && Boolean(errors.brokerDealerAssociatedControl)}
											required
										>
											<FormLabel required={false} id="broker-dealer-associated-control-label">
												Are you or an immediate family/household member a control person or affiliate of a publicly
												traded company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder,
												policy-making officer, member of the board of directors, etc.)?
											</FormLabel>
											<RadioGroup
												id="brokerDealerAssociatedControl"
												aria-labelledby="broker-dealer-associated-control-label"
												name="brokerDealerAssociatedControl"
												tabIndex={-1}
												value={values.brokerDealerAssociatedControl}
												onChange={(e) => {
													const { name, value } = e.target;
													setFieldValue(name, value).then(() => {
														validateField(name);
													});
												}}
											>
												<FormControlLabel
													value="Yes"
													label="Yes"
													control={<Radio disabled={isSubmitting || locked} />}
													disabled={isSubmitting || locked}
												/>
												<FormControlLabel
													value="No"
													label="No"
													control={<Radio disabled={isSubmitting || locked} />}
													disabled={isSubmitting || locked}
												/>
											</RadioGroup>
											{touched.brokerDealerAssociatedControl && errors.brokerDealerAssociatedControl && (
												<FormHelperText>{errors.brokerDealerAssociatedControl}</FormHelperText>
											)}
										</FormControl>
									</Grid>
								)}
								{values.brokerDealerAssociatedControl === 'Yes' &&
									renderExplanationInput('brokerDealerAssociatedControlExplanation')}

								{values.brokerDealerAssociated === 'Yes' && (
									// Are you or an immediate family/household member a senior foreign political figure?
									<Grid size={12}>
										<FormControl
											component="fieldset"
											required
											error={touched.brokerDealerAssociatedPolitical && Boolean(errors.brokerDealerAssociatedPolitical)}
										>
											<FormLabel required={false} id="broker-dealer-associated-political-label">
												Are you or an immediate family/household member a senior foreign political figure?
											</FormLabel>
											<RadioGroup
												id="brokerDealerAssociatedPolitical"
												aria-labelledby="broker-dealer-associated-political-label"
												name="brokerDealerAssociatedPolitical"
												tabIndex={-1}
												value={values.brokerDealerAssociatedPolitical}
												onChange={(e) => {
													const { name, value } = e.target;
													setFieldValue(name, value).then(() => {
														validateField(name);
													});
												}}
											>
												<FormControlLabel
													value="Yes"
													label="Yes"
													control={<Radio disabled={isSubmitting || locked} />}
													disabled={isSubmitting || locked}
												/>
												<FormControlLabel
													value="No"
													label="No"
													control={<Radio disabled={isSubmitting || locked} />}
													disabled={isSubmitting || locked}
												/>
											</RadioGroup>
											{touched.brokerDealerAssociatedPolitical && errors.brokerDealerAssociatedPolitical && (
												<FormHelperText>{errors.brokerDealerAssociatedPolitical}</FormHelperText>
											)}
										</FormControl>
									</Grid>
								)}
								{values.brokerDealerAssociatedPolitical === 'Yes' &&
									renderExplanationInput('brokerDealerAssociatedPoliticalExplanation')}
							</InfoBlock>
						</Grid>

						<Grid size={12}>
							<InfoBlock title={'Address information'}>
								{/* Current Mailing Address */}
								<Grid size={12}>
									<FormControl fullWidth>
										<GoogleMapSelect
											showAsterisk={false}
											label={'Mailing Address'}
											name="currentMailingAddress"
											value={values.currentMailingAddress}
											required
											aria-labelledby="mailing-address-label"
											handleChange={handleChange}
											setFieldValue={setFieldValue}
											disabled={locked || isSubmitting}
											fullWidth
										/>
									</FormControl>
								</Grid>

								{/* Have you been residing at this address less than 1 year? */}
								<Grid size={12}>
									<FormControl
										component="fieldset"
										error={touched.residingTime && Boolean(errors.residingTime)}
										required
									>
										<FormLabel required={false} id="residing-time-label">
											Have you been residing at this address less than 1 year?
										</FormLabel>
										<RadioGroup
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});

												if (value === 'No') {
													setFieldValue('formerAddress', '');
												}
											}}
											id="residingTime"
											aria-labelledby="residing-time-label"
											name="residingTime"
											tabIndex={-1}
											value={values.residingTime}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.residingTime && errors.residingTime && (
											<FormHelperText>{errors.residingTime}</FormHelperText>
										)}
									</FormControl>
								</Grid>
								{values.residingTime === 'Yes' && (
									<Grid size={12}>
										<FormControl fullWidth>
											<GoogleMapSelect
												showAsterisk={false}
												label={'Former Address'}
												name="formerAddress"
												value={values.formerAddress}
												required
												aria-labelledby="former-address-label"
												handleChange={handleChange}
												setFieldValue={setFieldValue}
												disabled={locked || isSubmitting}
												fullWidth
											/>
										</FormControl>
									</Grid>
								)}
							</InfoBlock>
						</Grid>

						<Grid size={12}>
							<InfoBlock title={'Attestations'} sx={undefined}>
								{/* Attestations */}
								<Grid size={12}>
									<FormControl component="fieldset" error={touched.rule506 && Boolean(errors.rule506)} required>
										<Stack spacing={1} direction={'row'} alignItems={'center'}>
											<FormLabel required={false} id="rule-506-label">
												Have you commited any bad acts as defined in Rule 506(d) of Regulation D?
											</FormLabel>
											<Tooltip
												placement="top"
												title={
													<span>
														{[
															'Under the final rule, disqualifying events include:',
															<br />,
															'• Certain criminal convictions',
															<br />,
															'• Certain court injunctions and restraining orders',
															<br />,
															'• Final orders of certain state and federal regulators',
															<br />,
															'• Certain SEC disciplinary orders',
															<br />,
															'• Certain SEC cease-and-desist orders',
															<br />,
															'• SEC stop orders and orders suspending the Regulation A exemption',
															<br />,
															'• Suspension or expulsion from membership in a self-regulatory organization (SRO), such as FINRA, or from association with an SRO member',
															<br />,
															'• U.S. Postal Service false representation orders"',
														]}
													</span>
												}
											>
												<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
											</Tooltip>
										</Stack>

										<RadioGroup
											id="rule506"
											aria-labelledby="rule-506-label"
											name="rule506"
											tabIndex={-1}
											value={values.rule506}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.rule506 && errors.rule506 && <FormHelperText>{errors.rule506}</FormHelperText>}
									</FormControl>
								</Grid>
								{values.rule506 === 'Yes' && renderExplanationInput('rule506Explanation')}
								<Grid size={12}>
									<FormControl
										component="fieldset"
										error={touched.criminalOffense && Boolean(errors.criminalOffense)}
										required
									>
										<FormLabel required={false} id="criminal-offense-label">
											Have you ever been convicted of a federal, state, or municipal criminal offense?
										</FormLabel>
										<RadioGroup
											id="criminalOffense"
											aria-labelledby="criminal-offense-label"
											name="criminalOffense"
											tabIndex={-1}
											value={values.criminalOffense}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.criminalOffense && errors.criminalOffense && (
											<FormHelperText>{errors.criminalOffense}</FormHelperText>
										)}
									</FormControl>
								</Grid>
								{values.criminalOffense === 'Yes' && renderExplanationInput('criminalOffenseExplanation')}
								<Grid size={12}>
									<FormControl
										component="fieldset"
										error={touched.pendingLegalLitigation && Boolean(errors.pendingLegalLitigation)}
										required
									>
										<FormLabel required={false} id="pending-legal-label">
											Do you or your affiliates currently have any pending legal litigation against you?
										</FormLabel>
										<RadioGroup
											id="pendingLegalLitigation"
											aria-labelledby="pending-legal-label"
											name="pendingLegalLitigation"
											tabIndex={-1}
											value={values.pendingLegalLitigation}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.pendingLegalLitigation && errors.pendingLegalLitigation && (
											<FormHelperText>{errors.pendingLegalLitigation}</FormHelperText>
										)}
									</FormControl>
								</Grid>
								{values.pendingLegalLitigation === 'Yes' && renderExplanationInput('pendingLegalLitigationExplanation')}
								<Grid size={12}>
									<FormControl component="fieldset" error={touched.judgments && Boolean(errors.judgments)} required>
										<FormLabel required={false} id="judgments-label">
											Do you or your affiliates currently have any judgments?
										</FormLabel>
										<RadioGroup
											id="judgments"
											aria-labelledby="judgments-label"
											name="judgments"
											tabIndex={-1}
											value={values.judgments}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.judgments && errors.judgments && <FormHelperText>{errors.judgments}</FormHelperText>}
									</FormControl>
								</Grid>
								{values.judgments === 'Yes' && renderExplanationInput('judgmentsExplanation')}
								<Grid size={12}>
									<FormControl component="fieldset" error={touched.foreclosure && Boolean(errors.foreclosure)} required>
										<FormLabel required={false} id="foreclosure-label">
											Have you or your affiliates ever filed for bankruptcy, had a foreclosure, or granted a deed in
											lieu of foreclosure?
										</FormLabel>
										<RadioGroup
											id="foreclosure"
											aria-labelledby="foreclosure-label"
											name="foreclosure"
											tabIndex={-1}
											value={values.foreclosure}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.foreclosure && errors.foreclosure && <FormHelperText>{errors.foreclosure}</FormHelperText>}
									</FormControl>
								</Grid>
								{values.foreclosure === 'Yes' && renderExplanationInput('foreclosureExplanation')}
								<Grid size={12}>
									<FormControl component="fieldset" error={touched.proceedings && Boolean(errors.proceedings)} required>
										<FormLabel required={false} id="proceedings-label">
											Any investor lawsuits, stop orders, SEC/FINRA proceedings, state securities proceedings?
										</FormLabel>
										<RadioGroup
											id="proceedings"
											aria-labelledby="proceedings-label"
											name="proceedings"
											tabIndex={-1}
											value={values.proceedings}
											onChange={(e) => {
												const { name, value } = e.target;
												setFieldValue(name, value).then(() => {
													validateField(name);
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												label="Yes"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
											<FormControlLabel
												value="No"
												label="No"
												control={<Radio disabled={isSubmitting || locked} />}
												disabled={isSubmitting || locked}
											/>
										</RadioGroup>
										{touched.proceedings && errors.proceedings && <FormHelperText>{errors.proceedings}</FormHelperText>}
									</FormControl>
								</Grid>
								{values.proceedings === 'Yes' && renderExplanationInput('proceedingsExplanation')}
							</InfoBlock>
						</Grid>
					</Grid>
				</Box>
			</Stack>
		</Stack>
	);
};

export default ShareholderProfileBlock;
