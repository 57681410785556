import {
	Box,
	Button,
	Card,
	CardMedia,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	Link,
	Stack,
} from '@mui/material';
import { DeleteOutlined, FileUpload } from '@mui/icons-material';
import React, { useRef } from 'react';
import { useFormikContext } from 'formik';

interface UploadDocumentsProps {
	locked: boolean;
	fieldName: string;
	label: string;
	description?: string;
}

const UploadDocuments: React.FC<UploadDocumentsProps> = (props) => {
	const { locked, fieldName, label, description } = props;
	const { touched, errors, isSubmitting, values, setFieldValue, validateField } = useFormikContext();

	const documentInputRef = useRef(null);

	const handleRemoveDocument = (fileId: any, index: any) => {
		if (locked) {
			return;
		}
		const result = values[fieldName].filter((file: any, fileIndex: any) =>
			fileId ? file.id !== fileId : fileIndex !== index
		);

		//control input
		const found = result.find(
			(file) => documentInputRef.current.value && documentInputRef.current.value.includes(file.filename || file.name)
		);

		if (!found) {
			documentInputRef.current.value = null;
		}

		setFieldValue(fieldName, result).then(() => {
			validateField(fieldName);
		});
	};

	return (
		<Stack spacing={2}>
			<FormControl component="fieldset" error={touched[fieldName] && Boolean(errors[fieldName])}>
				<Stack spacing={1}>
					<FormLabel id={`${fieldName}-label`}>{label}</FormLabel>
					<Stack spacing="4px">
						<Button
							id={fieldName}
							name={fieldName}
							variant="contained"
							sx={{ width: 180 }}
							endIcon={<FileUpload />}
							disabled={isSubmitting || locked}
							onClick={() => documentInputRef.current?.click()} // Trigger the input file click
							aria-labelledby={`${fieldName}-label`}
						>
							Upload Files
						</Button>
						<input
							type="file"
							hidden
							multiple
							ref={documentInputRef}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const newFiles = Array.from(e.target.files ?? []);
								const currentFiles = values[fieldName] ?? [];

								// Prevent duplicate files
								const uniqueFiles = newFiles.filter(
									(file) => !currentFiles.some((f: File) => f.name === file.name && f.size === file.size)
								);

								if (uniqueFiles.length > 0) {
									setFieldValue(fieldName, [...currentFiles, ...uniqueFiles]).then(() => {
										validateField(fieldName);
									});
								}

								// Reset the input value to allow re-uploading the same file
								e.target.value = '';
							}}
						/>
						{touched[fieldName] && errors[fieldName] && (
							<FormHelperText>
								{typeof errors[fieldName] === 'string' ? errors[fieldName] : 'Unknown error'}
							</FormHelperText>
						)}
						{Boolean(description) && <FormHelperText error={false}>{description}</FormHelperText>}
					</Stack>
				</Stack>
			</FormControl>
			{values[fieldName]?.length > 0 && (
				<Stack spacing={1}>
					{values[fieldName].map((file, index) => (
						<Card sx={{ display: 'flex', alignItems: 'center', padding: '0 10px 0 0' }} key={file.url ?? file.name}>
							<Stack spacing={2} direction={'row'} alignItems={'center'} flex={1} overflow={'hidden'}>
								<CardMedia
									component="img"
									sx={{ height: 60, width: 60 }}
									image={file.url ?? '/illustrations/dark/FileImageBackup.png'}
									onError={(e) => {
										e.currentTarget.src = '/illustrations/dark/FileImageBackup.png';
									}}
								/>
								<Link
									color={'text.primary'}
									href={file.url}
									target="_blank"
									rel="noopener noreferrer"
									underline={file.url ? 'hover' : 'none'}
									noWrap
								>
									{file.filename ?? file.name}
								</Link>
							</Stack>
							{!(isSubmitting || locked) && (
								<Box flexShrink={0}>
									<IconButton onClick={() => handleRemoveDocument(file.id, index)} size={'medium'}>
										<DeleteOutlined />
									</IconButton>
								</Box>
							)}
						</Card>
					))}
				</Stack>
			)}
		</Stack>
	);
};

export default UploadDocuments;
