import React from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Link,
	MenuItem,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { companyAffiliateYes, companyInsiderYes, OnboardingForm } from '../../formConstants';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { HelpOutline } from '@mui/icons-material';
import { timeFormat } from 'd3';
import InputField from '../../../../components/FormComponents/InputField';
import Select from '../../../../components/FormComponents/Select';
import { addMonths } from '../../../../utils/getDate';
import UploadDocuments from '../../../../components/FormComponents/UploadDocuments';

interface OnboardingBlockProps {
	locked: boolean;
}

const OnboardingBlock: React.FC<OnboardingBlockProps> = (props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const formikProps = useFormikContext<OnboardingForm>();
	const { values, isSubmitting, setFieldValue, errors, touched, validateField } = formikProps;
	const { locked } = props;

	const renderExplanationInput = (name) => (
		<Grid size={12}>
			<InputField
				label="Provide a written explanation"
				multiline
				fullWidth
				rows={4}
				name={name}
				disabled={locked || isSubmitting}
				required
				showAsterisk={false}
			/>
		</Grid>
	);

	return (
		<Stack
			spacing={4}
			sx={{ p: isMobile ? 3 : '32px 40px', borderRadius: '4px', border: `1px solid ${theme.palette.divider}` }}
		>
			<Stack spacing={2}>
				<Typography variant="h5">My stock</Typography>
				<Stack spacing={'4px'} alignItems={'flex-start'}>
					<Typography variant="body2" color={'text.secondary'}>
						Please find our standard Seller Firm Order ticket and Referral Agreement:{' '}
					</Typography>
					<Link
						component={RouterLink}
						color={'primary.main'}
						underline={'hover'}
						variant="body2"
						to="/data/seller_ticket_example.pdf"
						target="_blank"
						download
					>
						Download seller ticket example
					</Link>
					<Link
						component={RouterLink}
						color={'primary.main'}
						underline={'hover'}
						variant="body2"
						to="/data/referral_agreement_example.pdf"
						target="_blank"
						download
					>
						Download referral agreement example
					</Link>
				</Stack>
			</Stack>
			<Box>
				<Grid container spacing={[3, 4]}>
					{/* Are you still working at the company? */}
					<Grid size={12}>
						<FormControl
							required={true}
							component="fieldset"
							error={touched.stillInCompany && Boolean(errors.stillInCompany)}
						>
							<FormLabel required={false} id="still-in-company-label">
								Are you still working at the company?
							</FormLabel>
							<RadioGroup
								id="stillInCompany"
								aria-labelledby="still-in-company-label"
								name="stillInCompany"
								tabIndex={-1}
								value={values.stillInCompany}
								onChange={(e) => {
									const { name, value } = e.target;
									setFieldValue(name, value).then(() => {
										validateField(name);
									});
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.stillInCompany && errors.stillInCompany && (
								<FormHelperText>{errors.stillInCompany}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					{values.stillInCompany === 'Yes' && (
						<>
							<Grid size={12}>
								<InputField
									label={'Position'}
									name="position"
									fullWidth
									disabled={isSubmitting || locked}
									required
									showAsterisk={false}
								/>
							</Grid>
						</>
					)}
					{values.stillInCompany === 'No' && (
						<>
							<Grid size={12}>
								<Select
									fullWidth
									name={'whenDidYouLeave'}
									label={'When did you leave?'}
									required
									showAsterisk={false}
									disabled={isSubmitting || locked}
									onChange={(e) => {
										setFieldValue('whenDidYouLeave', e.target.value).then(() => {
											validateField('whenDidYouLeave');
										});
									}}
								>
									<MenuItem value={'less than 90 days ago'} key={'less than 90 days ago'}>
										Less than 90 days ago
									</MenuItem>
									<MenuItem value={'more than 90 days ago'} key={'more than 90 days ago'}>
										More than 90 days ago
									</MenuItem>
								</Select>
							</Grid>
						</>
					)}
					{/* File Equity */}
					<Grid size={12}>
						<UploadDocuments locked={locked} fieldName={'Equity'} label={'Upload Equity Incentive Plan'} />
					</Grid>
					{/* File Share */}
					<Grid size={12}>
						<UploadDocuments
							locked={locked}
							fieldName={'Share'}
							label={'Upload Share certificate(s)'}
							description={'if the options have been exercised'}
						/>
					</Grid>
					{/* File Grant */}
					<Grid size={12}>
						<UploadDocuments
							locked={locked}
							fieldName={'Grant'}
							label={'Upload "Options grant holder details" page'}
							description={
								'This should show the grant number, option type, number of options granted, cancelled, exercised, vested, and the expiration date (if the options are unexercised)'
							}
						/>
					</Grid>
					{/* AON or Min */}
					<Grid size={12}>
						<FormControl required={true} component="fieldset" error={touched.aonOrMin && Boolean(errors.aonOrMin)}>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<FormLabel required={false} id="aon-or-min-label">
									AON (All or Nothing) vs. Min (Minimum Quantity)
								</FormLabel>
								<Tooltip
									placement="top"
									title="AON (All or Nothing) refers to a trading condition used on a buy or sell order which instructs a broker to execute the order in its entirety or to do nothing.  Min refers to Minimum Quantity, a trading condition used on a buy or sell order which instructs a broker to execute the order down to the minimum share or dollar quantity."
								>
									<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
								</Tooltip>
							</Stack>
							<RadioGroup
								id="aonOrMin"
								aria-labelledby="aon-or-min-label"
								name="aonOrMin"
								tabIndex={-1}
								value={values.aonOrMin}
								onChange={(e) => {
									const { name, value } = e.target;
									setFieldValue(name, value).then(() => {
										validateField(name);
									});
								}}
							>
								<FormControlLabel
									value="AON"
									label="AON"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="Min"
									label="Min"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.aonOrMin && errors.aonOrMin && <FormHelperText>{errors.aonOrMin}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.aonOrMin === 'Min' && (
						<Grid size={6}>
							<InputField
								label={'Min Qty'}
								name="minQty"
								fullWidth
								disabled={isSubmitting || locked}
								required
								showAsterisk={false}
							/>
						</Grid>
					)}
					{/* GTC or expiration date */}
					<Grid size={12}>
						<FormControl
							component="fieldset"
							required={true}
							error={touched.gtcOrExpirationDate && Boolean(errors.gtcOrExpirationDate)}
						>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<FormLabel required={false} id="gtc-or-expiration-date-label">
									GTC (Good ‘til Canceled) vs. Expiration
								</FormLabel>
								<Tooltip
									placement="top"
									title="GTC Good ’til canceled (GTC) describes a type of order that a shareholder or investor may place to buy or sell a security that remains active until either the order is filled or the shareholder or investor cancels it.  When there is a Expiration a date added that is used to indicate the date and time (4:00 pm Eastern Time) when the ticket automatically cancels."
								>
									<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
								</Tooltip>
							</Stack>
							<RadioGroup
								onChange={(e) => {
									const { name, value } = e.target;
									setFieldValue(name, value).then(() => {
										validateField(name);
									});

									if (!values.expirationDate) {
										setFieldValue('expirationDate', value === 'Expiration' ? timeFormat('%Y-%m-%d')(addMonths(3)) : '');
									}
								}}
								id="gtcOrExpirationDate"
								aria-labelledby="gtc-or-expiration-date-label"
								name="gtcOrExpirationDate"
								tabIndex={-1}
								value={values.gtcOrExpirationDate}
							>
								<FormControlLabel
									value="GTC"
									label="GTC"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="Expiration"
									label="Expiration"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.gtcOrExpirationDate && errors.gtcOrExpirationDate && (
								<FormHelperText>{errors.gtcOrExpirationDate}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					{values.gtcOrExpirationDate === 'Expiration' && (
						<Grid size={12}>
							<InputField
								label={'Date'}
								required
								showAsterisk={false}
								name="expirationDate"
								fullWidth
								disabled={isSubmitting || locked}
								type="date"
								InputLabelProps={{
									shrink: true,
									required: false,
								}}
							/>
						</Grid>
					)}
					{/* Company insider */}
					<Grid size={12}>
						<FormControl
							required={true}
							component="fieldset"
							error={touched.companyInsider && Boolean(errors.companyInsider)}
						>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<FormLabel required={false} id="company-insider-label">
									As per FINRA definition, are you a company insider?
								</FormLabel>
								<Tooltip
									placement="top"
									title="A company insider means any officer or director of the issuer, or person that performs a similar function, or any person who is, directly or indirectly, the beneficial owner of more than 10 percent of the outstanding units or shares of any equity security (See SEC Rule 15c2-11(e)(1))."
								>
									<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
								</Tooltip>
							</Stack>

							<RadioGroup
								onChange={(e) => {
									const { name, value } = e.target;
									setFieldValue(name, value).then(() => {
										validateField(name);
									});

									if (value !== companyInsiderYes) {
										setFieldValue('companyInsiderEmployed', '');
										setFieldValue('companyInsiderControl', '');
										setFieldValue('companyInsiderPolitical', '');
									}
								}}
								id="companyInsider"
								aria-labelledby="company-insider-label"
								name="companyInsider"
								tabIndex={-1}
								value={values.companyInsider}
							>
								<FormControlLabel
									value="No, I am not a company insider"
									label="No, I am not a company insider"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value={companyInsiderYes}
									label={companyInsiderYes}
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="I am not sure, what do you think?"
									label="I am not sure, what do you think?"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.companyInsider && errors.companyInsider && (
								<FormHelperText>{errors.companyInsider}</FormHelperText>
							)}
						</FormControl>
					</Grid>

					{values.companyInsider === companyInsiderYes && (
						// Are you or an immediate family/household member employed by or associated with the securities industry (e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other associated person of a FINRA or MSRB member firm) or a financial services regulator?
						<Grid size={12}>
							<FormControl
								required={true}
								component="fieldset"
								error={touched.companyInsiderEmployed && Boolean(errors.companyInsiderEmployed)}
							>
								<FormLabel required={false} id="company-insider-employed-label">
									Are you or an immediate family/household member employed by or associated with the securities industry
									(e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or
									other associated person of a FINRA or MSRB member firm) or a financial services regulator?
								</FormLabel>
								<RadioGroup
									id="companyInsiderEmployed"
									aria-labelledby="company-insider-employed-label"
									name="companyInsiderEmployed"
									tabIndex={-1}
									value={values.companyInsiderEmployed}
									onChange={(e) => {
										const { name, value } = e.target;
										setFieldValue(name, value).then(() => {
											validateField(name);
										});
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.companyInsiderEmployed && errors.companyInsiderEmployed && (
									<FormHelperText>{errors.companyInsiderEmployed}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.companyInsiderEmployed === 'Yes' && renderExplanationInput('companyInsiderEmployedExplanation')}

					{values.companyInsider === companyInsiderYes && (
						// Are you or an immediate family/household member a control person or affiliate of a publicly traded company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of the board of directors, etc.)?
						<Grid size={12}>
							<FormControl
								required={true}
								component="fieldset"
								error={touched.companyInsiderControl && Boolean(errors.companyInsiderControl)}
							>
								<FormLabel required={false} id="company-insider-control-label">
									Are you or an immediate family/household member a control person or affiliate of a publicly traded
									company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer,
									member of the board of directors, etc.)?
								</FormLabel>
								<RadioGroup
									id="companyInsiderControl"
									aria-labelledby="company-insider-control-label"
									name="companyInsiderControl"
									tabIndex={-1}
									value={values.companyInsiderControl}
									onChange={(e) => {
										const { name, value } = e.target;
										setFieldValue(name, value).then(() => {
											validateField(name);
										});
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.companyInsiderControl && errors.companyInsiderControl && (
									<FormHelperText>{errors.companyInsiderControl}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.companyInsiderControl === 'Yes' && renderExplanationInput('companyInsiderControlExplanation')}

					{values.companyInsider === companyInsiderYes && (
						// Are you or an immediate family/household member a senior foreign political figure?
						<Grid size={12}>
							<FormControl
								required={true}
								component="fieldset"
								error={touched.companyInsiderPolitical && Boolean(errors.companyInsiderPolitical)}
							>
								<FormLabel required={false} id="company-insider-political-label">
									Are you or an immediate family/household member a senior foreign political figure?
								</FormLabel>
								<RadioGroup
									id="companyInsiderPolitical"
									aria-labelledby="company-insider-political-label"
									name="companyInsiderPolitical"
									tabIndex={-1}
									value={values.companyInsiderPolitical}
									onChange={(e) => {
										const { name, value } = e.target;
										setFieldValue(name, value).then(() => {
											validateField(name);
										});
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.companyInsiderPolitical && errors.companyInsiderPolitical && (
									<FormHelperText>{errors.companyInsiderPolitical}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.companyInsiderPolitical === 'Yes' && renderExplanationInput('companyInsiderPoliticalExplanation')}

					{/* Company affiliate */}
					<Grid size={12}>
						<FormControl
							required={true}
							component="fieldset"
							error={touched.companyAffiliate && Boolean(errors.companyAffiliate)}
						>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<FormLabel required={false} id="company-affiliate-label">
									As per FINRA definition, are you a company affiliate?
								</FormLabel>
								<Tooltip
									placement="top"
									title="Affiliate of an issuer means a person that directly, or indirectly through one or more intermediaries, controls, or is controlled by, or is under common control with, such issuer (See SEC Rule 144(a)(1))."
								>
									<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
								</Tooltip>
							</Stack>
							<RadioGroup
								onChange={(e) => {
									const { name, value } = e.target;
									setFieldValue(name, value).then(() => {
										validateField(name);
									});

									if (value !== companyAffiliateYes) {
										setFieldValue('companyAffiliateEmployed', '');
										setFieldValue('companyAffiliateControl', '');
										setFieldValue('companyAffiliatePolitical', '');
									}
								}}
								id="companyAffiliate"
								aria-labelledby="company-affiliate-label"
								name="companyAffiliate"
								tabIndex={-1}
								value={values.companyAffiliate}
							>
								<FormControlLabel
									value="No, I am not a company affiliate"
									label="No, I am not a company affiliate"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value={companyAffiliateYes}
									label={companyAffiliateYes}
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="I am not sure, what do you think?"
									label="I am not sure, what do you think?"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.companyAffiliate && errors.companyAffiliate && (
								<FormHelperText>{errors.companyAffiliate}</FormHelperText>
							)}
						</FormControl>
					</Grid>

					{values.companyAffiliate === companyAffiliateYes && (
						// Are you or an immediate family/household member employed by or associated with the securities industry (e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other associated person of a FINRA or MSRB member firm) or a financial services regulator?
						<Grid size={12}>
							<FormControl
								required={true}
								component="fieldset"
								error={touched.companyAffiliateEmployed && Boolean(errors.companyAffiliateEmployed)}
							>
								<FormLabel required={false} id="company-affiliate-employed-label">
									Are you or an immediate family/household member employed by or associated with the securities industry
									(e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or
									other associated person of a FINRA or MSRB member firm) or a financial services regulator?
								</FormLabel>
								<RadioGroup
									id="companyAffiliateEmployed"
									aria-labelledby="company-affiliate-employed-label"
									name="companyAffiliateEmployed"
									tabIndex={-1}
									value={values.companyAffiliateEmployed}
									onChange={(e) => {
										const { name, value } = e.target;
										setFieldValue(name, value).then(() => {
											validateField(name);
										});
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.companyAffiliateEmployed && errors.companyAffiliateEmployed && (
									<FormHelperText>{errors.companyAffiliateEmployed}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.companyAffiliateEmployed === 'Yes' && renderExplanationInput('companyAffiliateEmployedExplanation')}

					{values.companyAffiliate === companyAffiliateYes && (
						// Are you or an immediate family/household member a control person or affiliate of a publicly traded company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of the board of directors, etc.)?
						<Grid size={12}>
							<FormControl
								required={true}
								component="fieldset"
								error={touched.companyAffiliateControl && Boolean(errors.companyAffiliateControl)}
							>
								<FormLabel required={false} id="company-affiliate-control-label">
									Are you or an immediate family/household member a control person or affiliate of a publicly traded
									company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer,
									member of the board of directors, etc.)?
								</FormLabel>
								<RadioGroup
									id="companyAffiliateControl"
									aria-labelledby="company-affiliate-control-label"
									name="companyAffiliateControl"
									tabIndex={-1}
									value={values.companyAffiliateControl}
									onChange={(e) => {
										const { name, value } = e.target;
										setFieldValue(name, value).then(() => {
											validateField(name);
										});
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.companyAffiliateControl && errors.companyAffiliateControl && (
									<FormHelperText>{errors.companyAffiliateControl}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.companyAffiliateControl === 'Yes' && renderExplanationInput('companyAffiliateControlExplanation')}

					{values.companyAffiliate === companyAffiliateYes && (
						// Are you or an immediate family/household member a senior foreign political figure?
						<Grid size={12}>
							<FormControl
								required={true}
								component="fieldset"
								error={touched.companyAffiliatePolitical && Boolean(errors.companyAffiliatePolitical)}
							>
								<FormLabel required={false} id="company-affiliate-political-label">
									Are you or an immediate family/household member a senior foreign political figure?
								</FormLabel>
								<RadioGroup
									id="companyAffiliatePolitical"
									aria-labelledby="company-affiliate-political-label"
									name="companyAffiliatePolitical"
									tabIndex={-1}
									value={values.companyAffiliatePolitical}
									onChange={(e) => {
										const { name, value } = e.target;
										setFieldValue(name, value).then(() => {
											validateField(name);
										});
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.companyAffiliatePolitical && errors.companyAffiliatePolitical && (
									<FormHelperText>{errors.companyAffiliatePolitical}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.companyAffiliatePolitical === 'Yes' && renderExplanationInput('companyAffiliatePoliticalExplanation')}
				</Grid>
			</Box>
		</Stack>
	);
};

export default OnboardingBlock;
