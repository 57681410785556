import { CONTENT_SNIPPET_STATUS } from '../interfaces/IContentSnippet';

export const constants = {
	ADMIN_MAP: {
		'derek@saxonweber.com': 'Derek',
		'saloni@saxonweber.com': 'Saloni',
		'juliette@saxonweber.com': 'Juliette',
		'nish@saxonweber.com': 'Nish',
		'knirjaj@saxonweber.com': 'Kit',
		'sneha@saxonweber.com': 'Sneha',
	},
	USER: {
		TYPE: ['An Individual Shareholder', 'An Accredited Investor', 'An Institutional Firm'],
		USER_STAGE_GLOBAL: ['Retail', 'Institutional'],
		USER_STAGE: ['Retail', 'Inst1', 'Inst2', 'Inst3', 'Inst3Ask', 'Inst3Bid'],
		TIER: ['1', '2', '3'],
		INVESTOR_TYPE: ['Retail', 'Institutional', 'TBD'],
		STATUS: ['Initial', 'Granted', 'Denied'],
		TRIAD: ['Trader', 'Looker', 'Timer'],
		USER_TYPE: ['', 'Admin', 'NPC'],
		TRADER: ['Derek', 'Saloni', 'Kit'],
		ACCOUNT_TYPE: [
			'Venture Capital',
			'Private Equity',
			'Hedge Fund',
			'Asset Manager',
			'Family Office',
			'Corporate',
			'LP',
			'Law Firm',
			'Sovereign Wealth',
			'Broker Dealer',
			'Other',
		],
		COMPLIANCE: ['Yes', 'No (Broker Dealer)', 'No (Big Boy Letter)'],
	},
	INVESTOR: {
		TYPE: [
			'Venture Capital',
			'Private Equity',
			'Hedge Fund',
			'Asset Manager',
			'Family Office',
			'Corporate',
			'LP',
			'Law Firm',
			'Sovereign Wealth',
			'Broker Dealer',
			'Angel',
			'Other',
		],
		STATUS: ['Contacted', 'Replied', 'Not Replied', 'Accepted'],
	},
	INQUIRY: {
		ACTION: ['buy', 'sell'],
		STATUS_MAP: {
			Initial: 'Onboarding',
			'Compliance Requested': 'Onboarding',
			'Compliance Ongoing': 'Onboarding',
			'Compliance Approved': 'Onboarding',
			Docusign: 'Onboarding',
			'Live - Firm': 'Marketing',
			'Live - Indicative': 'Marketing',
			Matched: 'Execution',
			ROFR: 'Execution',
			'Settlement - Approved': 'Settlement',
			'Settlement - Blocked': 'Settlement',
			"Settlement - ROFR'd": 'Settlement',
			Invoicing: 'Closed',
			Closed: 'Closed',
			Cancelled: 'None',
			Expired: 'None',
			Ghost: 'None',
			Hold: 'None',
			Shadow: 'None',
		},
		INTERNAL_STATUS: [
			'Looker',
			'Initial',
			'Compliance Requested',
			'Compliance Ongoing',
			'Compliance Approved',
			'Docusign',
			'Live - Firm',
			'Live - Indicative',
			'Conditional',
			'Matched',
			'Cancelled',
			'Expired',
			'ROFR',
			'Settlement - Approved',
			'Settlement - Blocked',
			"Settlement - ROFR'd",
			'Invoicing',
			'Closed',
			'Ghost',
			'Hold',
			'Shadow',
		],
		INTERNAL_STATUS_INST: [
			'New',
			'WIP',
			'Live - Firm',
			'Matched',
			'Clearing',
			'Settlement',
			'Closed',
			'Cancelled',
			'Deleted',
		],
		INQUISTION_STATUS_MAP: {
			New: 'Initial',
			WIP: 'Initial',
			'Live - Firm': 'Marketing',
			Matched: 'Execution',
			Clearing: 'Execution',
			Settlement: 'Settlement',
			Closed: 'Closed',
			Cancelled: 'None',
		},
		// INVENTORY_STATUS: ['Initial', 'Live', 'Zombie', 'Sold'],
		AON_MIN: ['AON', 'Min'],
		GTC_EXP: ['GTC', 'Expiration'],
		BUYER_EXCERCISE: ['N/A', 'Requested', 'Covered'],
		TYPE: [
			'Options (NQSO)',
			'Options (ISO)',
			'Ordinary',
			'Common',
			'Common Class A',
			'Common Class B',
			'Common + Options (NQSO)',
			'Common + Options (ISO)',
			'Options (NQSO) + Options (ISO)',
			'Preferred',
		],
		TRANSACTION_TYPE: ['Forward', 'Direct', 'SPV', 'Cashless'],

		// institutional
		NUMERICAL_QUANTITIES: ['Thousands', 'Million', 'Billion'],
		OPTIONS_EXERCISE: ['Yes', 'No'],
		PRICE_TYPE: ['Valuation', 'Share price'],
		INCLUSIVE: ['Yes', 'No', 'Uncomfirmed'],
		STRUCTURE: ['Direct', 'Structured - Forwards', 'SPV'],
		SECURITY_TYPE: ['Common', 'Preferred', 'Any'],
		ENTITY_COMPLIANCE: ['Cleared', 'Requested', 'Missing'],
		SOURCE: ['Platform', 'Manual'],
		ORDER_CONFIRMATION: ['Ticket', 'Referral Agreement', 'Verbal'],
		ASSIGNEES: ['Derek', 'Saloni', 'Juliette', 'Nish', 'Sneha', 'Kit'],
		TICKET_LOCATION: ['Internal', 'ZX'],
		MARKET_VISIBILITY: ['Live market', 'Darkpool', 'Legacy'],
		TRADE_REP: ['William Blair', 'Kevin Kulak'],
		TRANSFER_POLICY: ['Yes', 'No'],
	},
	PROFILE: {
		INSTITUTIONAL: {
			CORPORATION_TYPE: [
				'LLC',
				'Corporation',
				'LIMITED PARTNERSHIP/GENERAL PARTNERSHIP',
				'TRUSTS (REVOCABLE AND IRREVOCABLE)',
			],
		},
	},
	COMPANY: {
		PRICING_EA: ['Estimate', 'Actual'],
	},
	ADMIN_ACTIVITY_LOG: {
		TYPE: ['Message', 'Intercom'],
	},
	ACTIVITY_LOG: {
		TYPE: {
			OnboardingPersonal: 'OnboardingPersonal',
			OnboardingTransactional: 'OnboardingTransactional',
			JointOnboarding: 'JointOnboarding',
		},
	},
	TASK: {
		LABEL: [
			'General',
			'Message',
			'Chat',
			'Compliance',
			'Data',
			'Authorize',
			'OTR',
			'ITO',
			'InstData',
			'Pricing',
			'TradingExe',
		],
		STATUS: ['Todo', 'In progress', 'Done'],
	},
	BID: {
		BUYER_EXERCISE: ['N/A', 'Requested', 'Covered'],
		TYPE: ['Firm', 'Indicative'],
		STATUS: ['Interested', 'Applied', 'Waiting for answer', 'Not Interested'],
		MARKET_VISIBILITY: ['Live market', 'Darkpool'],
	},
	OFFER: {
		CLASS: ['Common', 'Preferred'],
		STRUCTURE: ['Direct', 'SPV', 'Forward', 'Other'],
		LAYER: ['Single', 'Multi'],
		TYPE: ['Firm', 'Indicative'],
	},
	EMAIL: {
		FROM: ['Derek', 'ShareWell', 'Saloni', 'Nish', 'Kit'],
	},
	CONTENT_SNIPPET: {
		STATUS: [CONTENT_SNIPPET_STATUS.NEW, CONTENT_SNIPPET_STATUS.DONE],
		TAG: ['General', 'Institutional', 'Accredited', 'Retail'],
	},
};

//todo to be deleted
export const buyerSellerOptions = [
	{ label: 'Buy', value: 'buy' },
	{ label: 'Sell', value: 'sell' },
];
