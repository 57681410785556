import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import FormFields from './FormFields';
import { initialValues, schema } from './transactionForm.constants';
import { apiClient, endpoints } from '../../utils';
import PageTitle from '../PageTitle';
import ThankYouBlock from '../ThankYouBlock';
import LoadingButton from '@mui/lab/LoadingButton';
import InquiryPreview from '../InquiryPreview';
import FormikErrorFocus from 'formik-error-focus';
import Grid from '@mui/material/Grid2';

/**
 * @deprecated 'delete when it\'s not used'
 */
const TransactionForm = ({ defaultValues, locked, editing, inquiryData }) => {
	const { recId } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isButtonLoading, setButtonLoading] = useState(false);
	const [isSuccess, setSuccess] = useState(false);

	useEffect(() => {
		if (window.location.pathname.includes('success') && !isSuccess) {
			navigate(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
		}
	}, []);

	const handleSubmit = async (values) => {
		setButtonLoading(true);

		const { uploadDocuments } = values;
		const newFiles = [...uploadDocuments].filter((file) => !file.id);
		const uploadedFiles = [...uploadDocuments].filter((file) => file.id);

		for (const uploadedFile of uploadedFiles) {
			const blob = await fetch(uploadedFile.url).then((r) => r.blob());
			const parts = [blob];
			const file = new File(parts, uploadedFile.filename, { ...uploadedFile });
			newFiles.push(file);
		}

		const formData = new FormData();
		newFiles.forEach((file) => {
			formData.append('files', file);
		});

		const payload = JSON.stringify({ id: recId, questionnaire: JSON.stringify(values) });

		try {
			await apiClient.post(endpoints.uploadTransactionFiles.replace('{recordId}', recId), formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			const resQuestionnaire = await apiClient.post(endpoints.submitQuestionnaireForm, payload, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (resQuestionnaire.status === 200) {
				setSuccess(true);

				if (!window.location.pathname.includes('success')) {
					navigate(window.location.pathname + '/success');
				}
			}
		} catch (err) {
			console.log('handleSubmit.err', err);
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<>
			<PageTitle
				isOnlyBreadcrumbs={true}
				breadcrumbs={[
					{
						title: 'Home',
						link: '/home',
					},
					{
						title: locked ? 'Onboarding' : editing ? 'Edit onboarding' : 'Start onboarding',
					},
				]}
			/>
			<Formik
				enableReinitialize={true}
				initialValues={{ ...initialValues, ...defaultValues }}
				onSubmit={handleSubmit}
				validationSchema={schema}
				validateOnBlur={true}
			>
				{(formikProps) => {
					const { isSubmitting, setFieldValue, values, errors, touched } = formikProps;
					const inquiryPreview = <InquiryPreview {...inquiryData} />;

					return (
						<Grid container spacing={3}>
							{!isMobile && <Grid size={5}>{inquiryPreview}</Grid>}
							{!isMobile && (
								<Grid size={1}>
									<Divider orientation={'vertical'} sx={{ width: 'calc(100% / 2)' }} />
								</Grid>
							)}
							{isSuccess ? (
								<Grid size={isMobile ? 12 : 6} marginTop={'60px'} marginBottom={'60px'}>
									<ThankYouBlock
										title={'Thank you for your response!'}
										description={''}
										buttonText={'home'}
										buttonLink={'/'}
									/>
								</Grid>
							) : (
								<Grid size={isMobile ? 12 : 6}>
									<Stack spacing={isMobile ? 3 : 6}>
										{!locked && (
											<Stack spacing={'10px'}>
												<Typography variant="h5">Onboarding</Typography>
												<Typography variant="body2" color={'text.secondary'}>
													Please do your best to complete the the following fields accurately; which allows us to better
													understand your potential transactional preferences. You will have a chance to connect with
													your Relationship Manager to discuss pricing, process and market conditions once this
													information (and materials) have been collected.
												</Typography>
												<Typography variant="body2" color={'text.secondary'}>
													Please find our standard Seller Firm Order ticket for your review (we will fill it out for you
													once the compliance review has been cleared and approved):{' '}
												</Typography>
												<Link
													component={RouterLink}
													color={'primary.main'}
													underline={'hover'}
													variant="body2"
													to="/data/seller_ticket_example.pdf"
													target="_blank"
													download
												>
													Download seller ticket example
												</Link>
												<Link
													component={RouterLink}
													color={'primary.main'}
													underline={'hover'}
													variant="body2"
													to="/data/referral_agreement_example.pdf"
													target="_blank"
													download
												>
													Download referral agreement example
												</Link>
											</Stack>
										)}
										{isMobile && inquiryPreview}
										<Form autoComplete="off">
											<Stack spacing={isMobile ? 3 : 6}>
												<Box>
													<Grid container spacing={[3, '20px']}>
														<FormFields
															values={values}
															setFieldValue={setFieldValue}
															isSubmitting={isSubmitting}
															locked={locked}
															errors={errors}
															touched={touched}
														/>
														<FormikErrorFocus offset={0} align={'top'} focusDelay={0} formik={formikProps} />
													</Grid>
												</Box>
												{!locked && (
													<LoadingButton
														variant="contained"
														type="submit"
														sx={{ width: isMobile ? '100%' : '50%' }}
														size={'large'}
														loading={isButtonLoading}
														endIcon={<></>}
														loadingPosition={'end'}
													>
														Submit
													</LoadingButton>
												)}
											</Stack>
										</Form>
									</Stack>
								</Grid>
							)}
						</Grid>
					);
				}}
			</Formik>
		</>
	);
};

export default TransactionForm;
